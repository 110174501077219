<template>
  <div class="refund">
    <t-card title="基础信息:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="180rem" label-position="left">
        <el-form-item label="订单编号：">
          {{ form.order_number }}
        </el-form-item>
        <el-form-item label="学生姓名：">
          {{ form.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号：">
          {{ form.student_idcard }}
        </el-form-item>
        <el-form-item label="入账学校：">
          {{ form.school }}
        </el-form-item>
        <template v-if="form.order_type !== 3">
          <el-form-item label="购买服务名称：">
            {{ form.goods_pack_name }}
          </el-form-item>
          <el-form-item label="当前客户归属招生老师：">
            {{ form.belong_teacher }}
          </el-form-item>
        </template>
      </el-form>
    </t-card>

    <t-card title="缴费详情:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="180rem" label-position="left">
        <el-form-item label="应缴金额：">
          {{ form.money_answer }}
        </el-form-item>
        <el-form-item label="是否缴费：">
          {{ form.is_pay }}
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="Number(form.status)===3">
          {{ form.reason }}
        </el-form-item>
        <div v-else>
          <el-form-item label="实缴金额：">
            {{ form.paid_amount }}
          </el-form-item>
          <el-form-item label="缴费方式：">
            {{ form.payment_method }}
          </el-form-item>
          <el-form-item label="缴费时间：">
            {{ form.payment_at }}
          </el-form-item>
          <el-form-item label="付款人姓名：">
            {{ form.payer }}
          </el-form-item>
        </div>
      </el-form>
    </t-card>
    <t-card title="操作记录:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="180rem" label-position="left" style="display: flex">
        <el-form-item label="操作人：">
          {{ form.approval_creator }}
        </el-form-item>
        <el-form-item label="操作时间：" style="margin-left: 128rem">
          {{ form.approval_at }}
        </el-form-item>
      </el-form>
    </t-card>


    <div class="footer-button">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
    return {
      titleStyle: {
        borderBottom: '1rem solid #eee',
        paddingBottom: '10rem',
        display: 'block'
      },
      form: {},
    }
  },
  mounted() {
    this.$_register.get("api/recruit-v2/order-offline/show?id=" + this.$route.query.id).then(res => {
      this.form = res.data.data;
    })
  },
  components: {
    TCard: Card,
  }
}
</script>

<style lang="scss" scoped>
.refund {
  background: transparent !important;

  .card {
    background: white;
    padding: 10rem;
    margin-bottom: 20rem;

    .content {
      padding: 20rem 30rem;
    }
  }

  .footer-button {
    background: white;
    border: none;
    line-height: 80rem;
  }

  .timeline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20rem;

    .left {
      border-radius: 50%;
      background: #1a1d7a;
      width: 20rem;
      height: 20rem;
    }

    .right {
      flex: 1;
      margin-left: 20rem;
    }
  }

  .timeline:last-child {
    margin-bottom: 0;
  }
}

::v-deep .el-textarea {
  width: 15.06944444444444vw;
}

@media only screen and (max-width: 1139rem) {
  .el-textarea {
    max-width: 100%;
    width: 250rem !important;
  }
}
</style>
